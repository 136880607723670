<template>
  <v-form ref="formElem">
    <v-card>

      <v-card-text>

        <v-text-field v-model="form.name" label="Key" outlined :rules="[required]" class="mt-5" />

        <v-switch v-model="form.status" color="primary" true-value="active" false-value="obsoleted"
          :label="form.status ? 'Active' : 'Obsoleted'"></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" :loading="loading" @click="validate">
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert v-for="msg in errors" :key="msg" color="warning">
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('name', null),
      ...field('status', 'active'),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const typeOptions = ref([
      {
        name: 'Active',
        value: 'active',
      },
      {
        name: 'Obsoleted',
        value: 'obsoleted',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,

      typeOptions,
    }
  },
}
</script>
