import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { destroyPlan, fetchPlans } from '@api/medical/cardPlan'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    search: null,
    status: null,
  })

  const search = ref(null)

  const loadPlans = async () => {
    loading.value = true

    try {
      const params = {
        ...useTableOptions(options.value, parseFilters()),
        ...(search.value && {
          search: search.value,
        }),
      }

      const { data } = await fetchPlans(useTableOptions(options.value, params))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  const deletePlan = plan =>
    destroyPlan(plan.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadPlans()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadPlans()
  })

  watch(
    () => filters.search,
    (newId, oldId) => {
      options.value.page = 1
      loadPlans()
    },
    {
      deep: true,
    },
  )

  watch(
    () => filters.status,
    (newId, oldId) => {
      options.value.page = 1
      loadPlans()
    },
    {
      deep: true,
    },
  )

  watch(
    () => search.value,
    debounce(() => {
      options.value.page = 1

      loadPlans()
    }, 300),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,
    search,
    deletePlan,
    loadPlans,
  }
}
