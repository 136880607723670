<template>
  <div>

    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold "> {{$t('common.list.filters')}} </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row class="px-2 ma-0" v-show="showFilter">

          <v-col cols="12" sm="3">
            <v-text-field v-model="filters.search" single-line dense outlined hide-details clearable
              :placeholder="$t('common.list.searchByName')" />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select v-model="filters.status" placeholder="Status" :items="statuses"
              item-text="title" item-value="value" dense outlined clearable hide-details></v-select>
          </v-col>

        </v-row>
      </v-expand-transition>
    </v-card>

    <v-row class="my-0 d-flex justify-end">
      <v-col cols="12" sm="2" style="margin-right:auto" align="right">
        <v-btn color="primary" block class="" :loading="loading" :disabled="loading"
          @click="loadPlans">
          <v-icon class="prefix-icon">{{ icons.mdiRefresh }}</v-icon>
          <span>
            {{$t('common.list.refresh')}}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2" align="right">
        <v-btn depressed block color="success" @click="setAside(null)">
          <v-icon class="prefix-icon">{{ icons.mdiPlus }}</v-icon>
          {{$t('common.list.create')}}
        </v-btn>
      </v-col>
    </v-row>


    <v-card>
      <!-- table -->
      <v-data-table :headers="tableColumns" :items="tableItems" :options.sync="options"
        :server-items-length="tableTotal" :loading="loading" class="text-no-wrap">
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="setAside(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="deletePlan(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- <form-aside v-if="aside" v-model="aside" :resource="resource" @changed="
        loadPlans()
        aside = false
      "></form-aside> -->

    <plan-aside v-if="aside" v-model="aside" :resource="resource"
      @changed="loadPlans(); aside = false"></plan-aside>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiRefresh, mdiMenuDown, mdiMenuUp, mdiPlus } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref, computed } from '@vue/composition-api'
import usePlanList from './usePlanList'
import store from '@/store'
import dayjs from 'dayjs'
import PlanAside from './plan-resource/PlanAside.vue'

export default {
  components: {
    PlanAside,
  },

  setup() {
    const { tableItems, tableColumns, tableTotal, options, filters, search, loading, loadPlans, deletePlan } = usePlanList()

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const selectedAction = ref('')
    const resource = ref(null)

    const showFilter = ref(true)

    const statuses = ref([
      {
        title: 'Active',
        value: 'active',
      },
      {
        title: 'Obsoleted',
        value: 'obsoleted',
      },
    ])

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiMenuDown,
        mdiMenuUp,
        mdiPlus,
      },

      // filter
      statuses,

      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,
      search,

      selectedAction,
      showFilter,
      loadPlans,
      deletePlan,
      dayjs,
    }
  },
}
</script>

<style lang="scss" scoped></style>
